import { deviceWidth } from '@/utils/deviceWidth';
import { PercentageIcon } from '@/assets/svgExports/PercentageIcon';
import { RuppeeIcon } from '@/assets/svgExports/RuppeeIcon';
import React from 'react';
import { useMemo } from 'react';
import {
  CategoryCouponMiddleText,
  CategoryCouponContainer,
  CategoryCouponEnd,
  CategoryCouponLeftSlanted,
  CategoryCouponMiddle,
  CategoryCouponEndText,
  CategoryCouponLeftSlantedText,
  CategoryCouponContainerMobile,
  CategoryCouponTopLeftMobile,
  CategoryCouponTopLeftTextMobile,
  CategoryCouponMiddleTextMobile,
  CategoryCouponEndMobile,
} from './categorycoupon.styles';

const MAX_CATEGORYTAG_NAME = deviceWidth > 767 ? 4 : 2;

function renderCategoryTagName(coupon) {
  const categoryTagName = [];
  let categoryTagNamesTotalLength = 0;
  coupon?.category_id_list?.map((item) => {
    if (categoryTagName.length < MAX_CATEGORYTAG_NAME && item.name) {
      categoryTagName.push(item.name);
    }
  });

  coupon?.tag_id_list?.map((item) => {
    if (categoryTagName.length < MAX_CATEGORYTAG_NAME && item.name) {
      categoryTagName.push(item.name);
    }
  });

  const categoryTagNameRenderLength =
    MAX_CATEGORYTAG_NAME < categoryTagName.length
      ? MAX_CATEGORYTAG_NAME
      : categoryTagName.length;

  if (coupon?.category_id_list?.length && coupon?.tag_id_list?.length)
    categoryTagNamesTotalLength =
      coupon?.category_id_list?.length + coupon?.tag_id_list?.length;
  else {
    if (coupon?.category_id_list?.length) {
      categoryTagNamesTotalLength = coupon?.category_id_list?.length;
    } else if (coupon?.tag_id_list?.length) {
      categoryTagNamesTotalLength = coupon?.tag_id_list?.length;
    } else if (!coupon?.category_id_list?.length && !coupon?.tag_id_list?.length) {
      categoryTagNamesTotalLength = 0;
    }
  }
  return (
    <>
      <div className={deviceWidth > 767 ? 'f12px mt4px fw4' : 'f10px mt4px fw4'}>
        {categoryTagName.map((item, index) => {
          return index < categoryTagNameRenderLength - 1
            ? `${item}, `
            : index === categoryTagNameRenderLength - 1 && `${item}`;
        })}
        {categoryTagNamesTotalLength - MAX_CATEGORYTAG_NAME > 0
          ? ` +${categoryTagNamesTotalLength - MAX_CATEGORYTAG_NAME} more`
          : ''}
      </div>
    </>
  );
}

function categoryCouponDesktop(props, additionalProps) {
  return (
    <CategoryCouponContainer ml={props.desktopMarginLeft}>
      <CategoryCouponLeftSlanted>
        <div className="mr3">
          {additionalProps.discount_type === 'percentage' ? (
            <PercentageIcon color={'#EF8B36'} width={48} height={48}></PercentageIcon>
          ) : (
            <RuppeeIcon width={48} height={48}></RuppeeIcon>
          )}
        </div>
        <CategoryCouponLeftSlantedText>Special Offer</CategoryCouponLeftSlantedText>
      </CategoryCouponLeftSlanted>
      <CategoryCouponMiddle>
        <CategoryCouponMiddleText>
          <div className="fw7 f26px">
            {additionalProps.discount_type === 'percentage'
              ? `Get Upto ${props.coupon.discount}% off`
              : `FLAT ₹${props.coupon.discount} off`}
            <br></br>
          </div>
          {additionalProps.getCategoryTagNameToRender}
        </CategoryCouponMiddleText>
      </CategoryCouponMiddle>
      <CategoryCouponEnd>
        <CategoryCouponEndText>
          <div className="flex mr34px ba br2  b--dashed ph18px pv8px f13px b--moon-gray">
            Use Code:&nbsp;
            <div className="fw7 flex">{props.coupon.promo_code}</div>
          </div>
        </CategoryCouponEndText>
      </CategoryCouponEnd>
    </CategoryCouponContainer>
  );
}

function categoryCouponMobile(props, additionalProps) {
  return (
    <>
      <CategoryCouponTopLeftMobile>
        <div className="mr4px ml4px">
          {additionalProps.discount_type === 'percentage' ? (
            <PercentageIcon color={'#EF8B36'} width={12} height={12}></PercentageIcon>
          ) : (
            <RuppeeIcon width={12} height={12}></RuppeeIcon>
          )}
        </div>
        <CategoryCouponTopLeftTextMobile>Special Offer</CategoryCouponTopLeftTextMobile>
      </CategoryCouponTopLeftMobile>
      <CategoryCouponContainerMobile ml={props.mobileMarginLeft}>
        <CategoryCouponMiddleTextMobile>
          <div className="fw7 f16px">
            {additionalProps.discount_type === 'percentage'
              ? `Get Upto ${props.coupon.discount}% off`
              : `FLAT ₹${props.coupon.discount} off`}
            <br></br>
          </div>
          {additionalProps.getCategoryTagNameToRender}
        </CategoryCouponMiddleTextMobile>
        <CategoryCouponEndMobile>
          <div className="flex pv4px ph8px br2 ba b--dashed f10px b--moon-gray">
            Use Code:&nbsp;
            <div className="fw7 flex">{props.coupon.promo_code}</div>
          </div>
        </CategoryCouponEndMobile>
      </CategoryCouponContainerMobile>
    </>
  );
}

function CategoryCoupon(props) {
  const getCategoryTagNameToRender = useMemo(() => {
    return renderCategoryTagName(props.coupon);
  }, [props.coupon]);

  const discountType = props.coupon.discount_type;
  const additionalProps = {
    discount_type: discountType,
    getCategoryTagNameToRender: getCategoryTagNameToRender,
  };
  return deviceWidth > 767
    ? categoryCouponDesktop(props, additionalProps)
    : categoryCouponMobile(props, additionalProps);
}

export default CategoryCoupon;
