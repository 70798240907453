import { couponBannerAbsent, couponBannerPresent } from '@/redux/actions';
import { deviceWidth } from '@/utils/deviceWidth';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CategoryCoupon from '../CategoryCoupon';
import {
  CategoryCouponHorizontalViewContainer,
  SliderDot,
  SliderDotContainer,
} from './CategoryCouponHorizontalView.styles';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { useSSRSelector } from '@/redux/ssrStore';

const max_point_render = 9;
let pointToBeFilled = '0';

function CategoryCouponHorizontalView() {
  const router = useRouter();
  const dispatch = useDispatch();
  const cid = router.query.cid;
  const [couponIdNames, setCouponIdNames] = useState([]);
  const { couponList, isCustomWebsite } = useSSRSelector((state) => ({
    couponList: state.promoReducer.couponsList,
    isCustomWebsite: state.storeReducer.store?.theme?.custom_theme_flag,
  }));

  const getCategoryCoupons = useMemo(() => {
    const categorycoupons = [];
    if (couponList?.length) {
      for (let i = 0; i < couponList?.length; i++) {
        for (let j = 0; j < couponList[i]?.category_id_list?.length; j++) {
          if (couponList[i]?.category_id_list[j]?.id.toString() === cid) {
            categorycoupons.push(couponList[i]);
          }
        }
      }
    }
    if (categorycoupons.length > 0) {
      dispatch(couponBannerPresent());
    }
    return categorycoupons;
  }, [cid, couponList]);

  useEffect(() => {
    return () => dispatch(couponBannerAbsent());
  }, []);

  useEffect(() => {
    /* Fill couponIdNames to get their position after they are mapped & their id attribute has been set 
      thus, forcing next useEffect to hit  */
    !IS_SERVER &&
      getCategoryCoupons?.length &&
      !couponIdNames?.length &&
      getCategoryCoupons.forEach((promo, index) => {
        const couponIdNames = [];
        const uniqueCouponId = `${index}${promo.promo_id}`;
        couponIdNames.push(uniqueCouponId);
        setCouponIdNames(couponIdNames);
      });
  }, [getCategoryCoupons?.length]);

  useEffect(() => {
    if (
      !IS_SERVER &&
      couponIdNames?.length &&
      couponIdNames?.length === getCategoryCoupons?.length
    ) {
      const uniqueCouponIdNames = uniqueCouponId(couponIdNames);
      for (let i = 0; i < uniqueCouponIdNames.length; i++) {
        const Element = document.getElementById(uniqueCouponIdNames[i]);
        const pos = Element?.getBoundingClientRect()?.right;
        if (pos > 0 && pos < 1.2 * deviceWidth) {
          pointToBeFilled = uniqueCouponIdNames[i];
        }
      }
    }
  }, [couponIdNames?.length]);

  if (!isCustomWebsite) {
    return <></>;
  }

  function uniqueCouponId(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  function renderDottedCircle() {
    const uniqueCouponIdNames = uniqueCouponId(couponIdNames);
    return (
      <SliderDotContainer>
        {!!uniqueCouponIdNames?.length &&
          uniqueCouponIdNames.map((id_name, index) => {
            return (
              index < max_point_render && (
                <div id={id_name}>
                  <SliderDot
                    backgroundColor={pointToBeFilled === id_name ? 'black' : ''}
                  ></SliderDot>
                </div>
              )
            );
          })}
      </SliderDotContainer>
    );
  }

  return (
    !!getCategoryCoupons.length && (
      <>
        <CategoryCouponHorizontalViewContainer>
          {getCategoryCoupons.map((promo, index) => {
            const uniqueCouponId = `${index}${promo.promo_id}`;
            return (
              <div key={index} id={uniqueCouponId}>
                <CategoryCoupon
                  desktopMarginLeft={index === 0 ? '10.1vw' : '14px'}
                  coupon={promo}
                  mobileMarginLeft={index === 0 ? '14px' : '8px'}
                ></CategoryCoupon>
              </div>
            );
          })}
        </CategoryCouponHorizontalViewContainer>
        {renderDottedCircle()}
      </>
    )
  );
}

export default CategoryCouponHorizontalView;
