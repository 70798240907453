import styled from 'styled-components';

export const CategoryCouponHorizontalViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100vw;
  margin-bottom : 68px;
  -ms-overflow-style: none; 
  scrollbar-width: none; 

  &::-webkit-scrollbar {
    display: none;
  }
}
`;

export const SliderDotContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -52px;
  margin-bottom: 42px;
  @media screen and (max-width: 768px) {
    margin-bottom: 18px;
  }
`;

export const SliderDot = styled.div`
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 1rem;
  margin-left: 3px;
  border: 1px solid black;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'white'};
`;
