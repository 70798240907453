/**
 * function to calculate Y-axis position of arrow
 * considering the dynamic acpect ratio of card
 * if card offset width is not 300, calculate card width
 *
 * @param {*} commonConfigData
 * @param {*} cardHeight
 * @return {*} string
 */
export const calculateArrowTopPos = (commonConfigData = {}, cardHeight = 300) => {
  const { aspect_ratio = null } = commonConfigData;
  if (!aspect_ratio) return `50%`;
  const height = (cardHeight * (aspect_ratio / 100)) / 2;
  return height ? `${height}px` : `50%`;
};
