import styled from 'styled-components';

export const CategoryCouponLeftSlanted = styled.div`
  height: 112px;
  width: 353px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor || '#F8F8F8'};
  position: relative;
  overflow: hidden;
  &:after {
    height: 100%;
    width: 100%;
    background-color: white;
    position: absolute;
    content: '';
    transform: rotate(45deg);
    transform-origin: bottom right;
  }
`;

export const CategoryCouponLeftSlantedText = styled.div`
  font-size: 26px;
  font-weight: 700;
  margin-right: 56px;
`;

export const CategoryCouponMiddle = styled.div`
  height: 112px;
  width: 400px;
  background-color: ${props => props.backgroundColor || 'white'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CategoryCouponEnd = styled.div`
  height: 112px;
  width: 400px;
  background-color: ${props => props.backgroundColor || 'white'};
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CategoryCouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #efefef;
  width: 1200px;
  margin-left: ${props => props.ml};
`;

export const CategoryCouponMiddleText = styled.div`
  margin-left: -39px;
  margin-top: -13px;
`;

export const CategoryCouponEndText = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -24px;
`;

export const CategoryCouponContainerMobile = styled.div`
  height: 65px;
  width: 333px;
  display: flex;
  flex-direction: row;
  background-color: #fdfdfd;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #f8f8f8;
  margin-left: ${props => props.ml};
  justify-content: space-between;
`;

export const CategoryCouponTopLeftMobile = styled.div`
  height: 19px;
  width: 91px;
  display: flex;
  margin-left: 24px;
  flex-direction: row;
  background-color: #fdfdfd;
  border-radius: 10px;
  border: 1px solid #f8f8f8;
  transform: translateY(10px);
`;

export const CategoryCouponTopLeftTextMobile = styled.div`
  display: flex;
  font-size: 10px;
  align-items: center;
  font-weight: 500;
`;

export const CategoryCouponMiddleTextMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const CategoryCouponEndMobile = styled.div`
  background-color: ${props => props.backgroundColor || 'white'};
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
`;
