export const RuppeeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '45.498'}
      height={props.height || '45.498'}
      viewBox="0 0 45.498 45.498"
    >
      <g id="Group_160895" data-name="Group 160895" transform="translate(-218.258 -416)">
        <path
          id="Path_305453"
          data-name="Path 305453"
          d="M69.614,92.363A22.749,22.749,0,1,1,85.7,85.7a22.6,22.6,0,0,1-16.086,6.663Z"
          transform="translate(171.393 369.135)"
          fill="#18D8B3"
        />
        <path
          id="Path_305454"
          data-name="Path 305454"
          d="M186.026,146.741h0l-1.848,0a8.31,8.31,0,0,0-1.517-3.359l3.372-.006a1.681,1.681,0,0,0,0-3.362h0l-9.975.018-.106,0h-.02l-5.525.013a1.681,1.681,0,0,0,.011,3.362l5.641-.01a5.048,5.048,0,0,1,4.647,3.353l-10.3.019a1.681,1.681,0,0,0,0,3.362h0l10.293-.019a5.053,5.053,0,0,1-4.743,3.368l-5.557.013a1.7,1.7,0,0,0-1.115,2.935l12.265,10.936a1.681,1.681,0,0,0,2.237-2.509l-8.984-8.01,1.162,0a8.423,8.423,0,0,0,8.217-6.736l1.851,0a1.681,1.681,0,0,0,0-3.362Z"
          transform="translate(62.794 286.115)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
