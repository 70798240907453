import React from 'react';
import { useRouter } from 'next/router';
import { ProductCardWidget1 } from '../../ProductItemWidget';
import { ViewMore } from '../GridView/Hybrid.styles';
import { deviceWidth } from '@/utils/deviceWidth';
import { calculateArrowTopPos } from '@/utils/calculateArrowTopPos';
import { showViewMoreBtn } from '@/utils/showViewMoreBtn';
import { returnCommonConfigData } from '@/utils/returnCommonConfigData';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { getRoute, PATH } from '@/utils/routes';
import { CATEGORY_TYPE, ITEM_ACTIVE_STATUS } from '../../../../utils/constants';
import { Link } from '@/components/Link';
import { useSSRSelector } from '@/redux/ssrStore';

const CategoryProductHorizontalView = (props) => {
  const router = useRouter();
  const { activeCategoryData, categoryItems, onItemsImageClick, getLink } = props;
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  const maxItemRender = (items) => {
    const maxItemsToShow = props.config.max_items || 0;
    if (items?.length && maxItemsToShow) {
      const newArr = items.slice(0, maxItemsToShow);
      return newArr;
    }
    return items;
  };

  const onViewMoreCtaClick = () => {
    if (isPLPRequiredSystem(storeData?.theme?.theme_class)) {
      router.push(
        getRoute(
          `${PATH.SHOP}?cid=${activeCategoryData.id}`,
          storeData?.store_info?.domain
        ),
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        getRoute(
          `${PATH.PRODUCT}?cid=${activeCategoryData.id}`,
          storeData?.store_info?.domain
        ),
        undefined,
        { shallow: true }
      );
    }
  };

  const scrollNewReleaseSection = (scrollType, catId) => {
    const scrollMovPos = deviceWidth < 767 ? deviceWidth : 330;
    const element = document.getElementById(`scroll-wrapper-${catId}`);
    const leftArrowEle = document.getElementsByClassName(
      `category-list-left-arrow-${catId}`
    );
    const rightArrowEle = document.getElementsByClassName(
      `category-list-right-arrow-${catId}`
    );
    let scrollPos = 0;
    if (element) {
      scrollPos = element.scrollLeft;
      if (scrollType === 'left') {
        if (scrollPos < 130) scrollPos = 0;
        else scrollPos -= scrollMovPos;
      } else {
        scrollPos += scrollMovPos;
      }
      if (scrollPos >= 0) {
        element.scrollLeft = scrollPos;
        if (leftArrowEle) {
          !scrollPos
            ? leftArrowEle[0].classList.add('dn')
            : leftArrowEle[0].classList.remove('dn');
        }
        if (rightArrowEle) {
          if (scrollPos + element.offsetWidth >= element.scrollWidth) {
            rightArrowEle[0].classList.add('dn');
          } else {
            rightArrowEle[0].classList.remove('dn');
          }
        }
      }
    }
  };

  // calculate arrow Y axis position in px
  const arrowTopPos = () => {
    return calculateArrowTopPos(
      returnCommonConfigData({
        activeCategoryConfig: activeCategoryData?.products_config,
        productConfig: props?.config?.product_config,
      })
    );
  };

  if (
    activeCategoryData &&
    props?.categoryTagType === CATEGORY_TYPE.TAG &&
    !activeCategoryData?.is_active
  )
    return null;

  return (
    categoryItems?.length > 0 && (
      <div
        className="horizontal-category-display-widget relative mb5-ns mb0"
        style={{ padding: '0px 20px' }}
      >
        {props.config?.is_show_heading ? (
          <div
            className="horizontal-category-display-widget__title tc fw6"
            style={{
              margin: props?.config?.customStyle?.margin,
              textAlign: props?.config?.customStyle?.textAlign,
            }}
          >
            {activeCategoryData?.name}
          </div>
        ) : null}
        <div className="relative">
          {categoryItems?.length > 1 && (
            <>
              <img
                style={{
                  top: arrowTopPos(),
                }}
                alt="Left Button"
                src="/assets/icons/arrowLeftIconCircleDark.png"
                className={`category-list-left-arrow-${props?.config?.category_id} arrow-btns absolute left-1-ns left-0 img pointer dn`}
                onClick={() =>
                  scrollNewReleaseSection('left', props?.config?.category_id)
                }
              ></img>
              <img
                style={{
                  top: arrowTopPos(),
                }}
                alt="Right Button"
                src="/assets/icons/arrowRightIconCircleDark.png"
                className={`category-list-right-arrow-${props?.config?.category_id} arrow-btns absolute right-1-ns right-0 img pointer`}
                onClick={() =>
                  scrollNewReleaseSection('right', props?.config?.category_id)
                }
              ></img>
            </>
          )}
          <section
            className="scroll-wrapper"
            id={`scroll-wrapper-${props?.config?.category_id}`}
          >
            {/* <div className="ml1 ml5-ns ph2 ph5-ns"></div> */}
            {maxItemRender(categoryItems)?.map(
              (data) =>
                data?.is_active === ITEM_ACTIVE_STATUS.ACTIVE && (
                  <div className="category-card" key={data.id}>
                    <Link href={getLink(data)}>
                      <ProductCardWidget1
                        key={data.id}
                        data={data}
                        onItemClick={() => onItemsImageClick(data)}
                        showButton={props.showATCBtn}
                        noTags={activeCategoryData?.hasOwnProperty('is_custom_tag')}
                        categoryConfig={returnCommonConfigData({
                          activeCategoryConfig: activeCategoryData?.products_config,
                          productConfig: props?.config?.product_config,
                        })}
                        productConfig={props?.config?.product_config}
                      />
                    </Link>
                  </div>
                )
            )}
            {showViewMoreBtn(props?.config?.max_items, categoryItems) ? (
              <div
                className="category-card pointer flex items-start view-more-box w-100 mb5"
                onClick={onViewMoreCtaClick}
              >
                <div
                  className="view-more-box--inner relative"
                  style={{
                    paddingTop: `${
                      activeCategoryData?.products_config?.aspect_ratio ||
                      props?.config?.product_config?.aspect_ratio ||
                      10000 / storeData?.theme?.product_aspect_ratio ||
                      100
                    }%`,
                  }}
                >
                  <ViewMore>
                    {'View More'}
                    <span />
                  </ViewMore>
                </div>
              </div>
            ) : null}
            <div className="ml1 ml5-ns ph2 ph5-ns"></div>
          </section>
        </div>
      </div>
    )
  );
};

export default CategoryProductHorizontalView;
