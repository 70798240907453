import LocalStorageHelper from '@/utils/LocalStorageHelper';

export const PRODUCT_TAG_STORED_CONSTANT = 'PRODUCT_TAG_STORED_CONSTANT';

export const getCachedCategoryItems = () => {
  const data = {
    allowReset: true,
    categoryItems: [],
  };

  return LocalStorageHelper.get(PRODUCT_TAG_STORED_CONSTANT) || data;
};

export const cacheCategoryItems = (data) => {
  const currentData = getCachedCategoryItems();
  const updatedData = { ...currentData, ...data };
  LocalStorageHelper.add(PRODUCT_TAG_STORED_CONSTANT, updatedData);
};
