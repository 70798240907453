/**
 * returns boolean. conditionally render View More button
 * based on maxLimit and item list
 * @param {*} maxLimit number
 * @param {*} itemList array
 * @return {*} boolean
 */
export const showViewMoreBtn = (maxLimit = 0, itemList = []) => {
  if (!maxLimit && !itemList?.length) return false;
  return maxLimit ? itemList?.length > maxLimit : false;
};
